import React, { useEffect, useState } from "react";
import logo1 from "../images/1.png";
import logo2 from "../images/2.png";
import cta_image from "../images/cta_images.jpg";
import "./index.css";
import axios from "axios";
import { toast } from "react-toastify";
import logo from '../images/logo.png'; // Adjust the path as necessary



const navItems = [
  { title: "Home", class: "hero" },
  { title: "About", class: "about" },
  { title: "Services", class: "services" },
  { title: "Portfolio", class: "portfolio" },
  { title: "Pricing", class: "pricing" },
  { title: "Contact", class: "contact" },
];

const OnePageComponent = () => {
  const [scroll, setScroll] = useState(false);
  const [isMobile, setMobile] = useState(false);
  const [isActive, setActive] = useState("hero");
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  useEffect(() => {
    window.addEventListener("scroll", (e) => {
      setScroll(window.scrollY > 80);
      const select = (el, all = false) => {
        el = el.trim();
        if (all) {
          return [...document.querySelectorAll(el)];
        } else {
          return document.querySelector(el);
        }
      };
      let navbarlinks = select("#navbar .scrollto", true);
      let position = window.scrollY + 200;
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        let section = select(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          setActive(navbarlink.hash);
        }
      });
    });
  }, []);

  const navigate = (e, id) => {
    e && e.preventDefault(); // to avoid the link from redirecting
    const elementToView = document.getElementById(id);
    elementToView.scrollIntoView();
    setActive(id);
    setMobile(false);
  };
  const handleChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
    };


  
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            //const corsProxy = process.env.REACT_APP_CORS_PROXY || '';
            const url = process.env.REACT_APP_API_URL || '';
            const headers = {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            };
            const response = await axios.post(url, payload, { headers });
            if (response?.status < 400) {
                toast.success("Contact details submitted successfully");
                setPayload({
                    name: "",
                    email: "",
                    mobile: "",
                    message: "",
                });
            }
        } catch (error) {
            toast.error(error?.message || "Error submitting form");
        }
    };


  return (
    <div>
          <header
              id="header"
              className={`fixed-top ${scroll ? `header-scrolled` : ``}`}>
              <div className="container d-flex align-items-center justify-content-between">
                  <h1 className="logo">
                      <img
                          src={logo}
                          alt="StaySafe IT Solutions Logo"
                          style={{
                              maxHeight: '80px', // Adjust logo height
                              width: '200px'      // Let width adjust automatically to maintain aspect ratio
                          }}
                      />
                  </h1>
                  <nav
                      id="navbar"
                      className={`navbar ${isMobile ? `navbar-mobile` : ""}`}
                  >
                      <ul>
                          {navItems?.map((list) => {
                              return (
                                  <li key={list.title}>
                                      <a
                                          href={`#${list.class}`}
                                          className={`nav-link scrollto ${isActive.includes(list.class) ? "active" : ""
                                              }`}
                                          onClick={(e) => navigate(e, list.class)}
                                      >
                                          {list.title}
                                      </a>
                                  </li>
                              );
                          })}
                      </ul>
                      <i
                          className={`bi mobile-nav-toggle ${isMobile ? `bi-x` : `bi-list`} `}
                          onClick={() => setMobile(!isMobile)}
                      ></i>
                  </nav>
              </div>
          </header>

      {/* <!-- ======= Hero Section ======= --> */}
      <section id="hero">
        <div className="hero-container">
          <h3>
            Welcome to <strong>StaySafe IT Solutions</strong>
          </h3>
          <h1>Software and Website development company</h1>
          <span
            onClick={(e) => navigate(e, "about")}
            className="btn-get-started scrollto"
          >
            Get Started
          </span>
        </div>
      </section>
      {/* <!-- End Hero --> */}
      <main id="main">
        {/* <!-- ======= About Section ======= --> */}
       <section id="about" className="about">
  <div className="container">
    <div className="section-title">
      <h2>About</h2>
    </div>

                      <div className="row content">
    <h4>
      <p>
        At StaySafe IT Solutions, we specialize in crafting tailored mobile and web solutions to meet our clients' unique needs. Our skilled in-house team of developers, designers, and QA specialists ensures high-quality, timely delivery within budget.
      </p>
      <p>
        We prioritize collaboration and transparency, maintaining regular communication to align with your business goals. From custom mobile apps and responsive websites to comprehensive e-commerce platforms, we leverage the latest technologies to create innovative, user-friendly solutions.
      </p>
      <p>
        Dedicated to exceptional service and support, we aim to build lasting partnerships and drive your business's success through technology. Contact us today to see how we can help.
                              </p>
                          </h4>
    </div>
  </div>
</section>

        
        
        {/* <!-- ======= Services Section ======= --> */}
        <section id="services" className="services">
          <div className="container">
            <div className="section-title">
              <h2>Services</h2>
              <h3>
                Offered <span>Services</span>
              </h3>
              <p>
                We Offer a Plenty of services with very affordable pricing. We
                have specialization in Application Development in JAVA, Python,
                NodeJS, PHP, Golang, Mobile app Development, QA and Testing,
                Technical Support, Big Data, Digital Transformation, Cloud
                Computing, Robotic Process Automation, Reporting & Monitoring
                Dashboards and all other IT aspects.
              </p>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Application Development</span>
                  </h4>
                  <h6 className="sub-menu">JAVA, Python, NodeJS, PHP, Go,</h6>
                  <p className="description">
                    We develop commercial grade web applications and industry
                    best practices to ensure scalability, security and high
                    performance. focuses to improves your business in the most
                    innovative ways. We design websites and web applications to
                    be scalable, functional, and efficient.{" "}
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Mobile app Development</span>
                  </h4>
                  <h6 className="sub-menu">React native, Flutter</h6>
                  <p className="description">
                    We offer a complete spectrum of Hybrid App Development
                    services that include strategic planning, proper
                    implementation of Mobile Technology, app optimization,
                    quality check, technical support and maintenance. Hire our
                    mobile app development services and cover the end-to-end
                    customization needs effectively.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Digital Transformation</span>
                  </h4>
                  <h6 className="sub-menu">
                    IOT, ArtifIcial intelligence, ML{" "}
                  </h6>
                  <p className="description">
                    We work on IOT Apps, Embedded Software, IOT Hardware
                    Prototyping, IOT Dashboard and Analytics. Artificial
                    Intelligence and Machine Learning are two emerging trends in
                    technologies. StaySafe IT Solutions caters to the prominent AI and ML
                    to deliver high-end solutions. We provide intelligent and
                    data-driven insights that help your AI vision convert into
                    reality.{" "}
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Cloud Computing</span>
                  </h4>
                  <h6 className="sub-menu">Google cloud, azure, aws </h6>
                  <p className="description">
                    We help build apps using Amazon, Google, Microsoft Azure &
                    IBM Bluemix to leverage the cloud advantages and scale
                    on-demand with ease. We are premier cloud services and
                    technology staffing company providing cloud technology
                    services, and solutions.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Robotic Process Automation </span>
                  </h4>
                  <h6 className="sub-menu">iot, AI, machine learning </h6>
                  <p className="description">
                    Our company helps your business enhance and provide Image &
                    Video, Text To Speech, Business Intelligence, Data
                    Forecasting, Natural Language Processing, and Data
                    Analytics. These tools enable you to operate with a large
                    amount of data and valuable insight into the present and
                    future predictions.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Reporting & Monitoring </span>
                  </h4>
                  <h6 className="sub-menu">
                    Performance & productivity tracking
                  </h6>
                  <p className="description">
                    Automatic time tracking lets you know where the team excels
                    and where it needs help so you can easily improve individual
                    and overall performance.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>QA and Testing</span>
                  </h4>
                  <h6 className="sub-menu">SELENIUM, JIRA, UI DASHBOARD</h6>
                  <p className="description">
                    We offer End to end systems testing via using of manual and
                    automated testing with value-added services. We as an IT
                    software quality assurance firm which is committed to
                    provide Advisory, Support, and Implementation services to
                    enterprise-level clients in the IT space.{" "}
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Technical Support</span>
                  </h4>
                  <h6 className="sub-menu">Slack, email, 24x7 </h6>
                  <p className="description">
                    We are here to help you with your digital needs. No question
                    is too small, or too big. Ask a question and we will make
                    sure you get answers to your questions. We are available
                    24x7 for technical assistance.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Digital Marketing</span>
                  </h4>
                  <p className="description">
                    StaySafe IT Solutions Solutions provide social media marketing, graphic
                    design and Marketing Mixologists craft smart strategies for
                    digital advertising, SEO, social media, and email marketing
                    services in India.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Sales Optimization</span>
                  </h4>
                  <p className="description">
                    Our conversion rate optimization and digital transformation
                    strategies improve sales leads, engagements, and market
                    share. StaySafe IT Solutions provide very affordable prices for our
                    services.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>SEO</span>
                  </h4>
                  <p className="description">
                    Search engine optimization regulations always evolve we stay
                    on top of the latest changes to help clients rank higher.
                  </p>
                </div>
              </div>
            </div>

            <div className="section-title mt-5">
              <h3>
                Offered <span>Development</span>
              </h3>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>E-commerce Development</span>
                  </h4>
                  <p className="description">
                    StaySafe IT Solutions can provide you with a ready-to-go team at an
                    affordable cost for small retailers, startups, SMBs or
                    enterprises. In our talent pool, there are web developers
                    with relevant experience in the e-commerce industry.
                  </p>
                </div>
              </div>

              <div className="col-md-12 col-lg-6 d-flex align-items-stretch mb-5 mb-lg-0">
                <div className="icon-box">
                  <div className="icon">
                    <i className="bx bxl-dribbble"></i>
                  </div>
                  <h4 className="title">
                    <span>Custom Web Development</span>
                  </h4>
                  <p className="description">
                    Wanna add distinctive features to your site or app? We
                    appreciate personal approach and bespoke web solutions. Our
                    project-based or dedicated web developers will listen to
                    your demands and suggest the ways to carry it out. web
                    development services in India.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        
        {/* <!-- End Services Section --> */}

        {/* <!-- ======= Features Section ======= --> */}
        <section id="features" className="features">
          <div className="container">
            <div className="row">
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i className="ri-store-line" style={{ color: "#189ad6" }}></i>
                  <h3>
                    <span>JAVA</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-bar-chart-box-line"
                    style={{ color: "#21cfaa" }}
                  ></i>
                  <h3>
                    <span>Python</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-calendar-todo-line"
                    style={{ color: "#b60c0c" }}
                  ></i>
                  <h3>
                    <span>GO</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-file-list-3-line"
                    style={{ color: "#088a4d" }}
                  ></i>
                  <h3>
                    <span>Flutter</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-file-list-3-line"
                    style={{ color: "#088a4d" }}
                  ></i>
                  <h3>
                    <span>Swift</span>
                  </h3>
                </div>
              </div>
              {/* <div className="col-lg-3 col-md-4 col-6 mt-2 mb-2" onClick={(e) => navigate(e, 'contact')}>
                                <div className="icon-box">
                                    <i className="ri-file-list-3-line" style={{ color: '#088a4d' }}></i>
                                    <h3><span>SwiftUI</span></h3>
                                </div>
                            </div> */}
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-file-list-3-line"
                    style={{ color: "#088a4d" }}
                  ></i>
                  <h3>
                    <span>CI/CD</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-file-list-3-line"
                    style={{ color: "#088a4d" }}
                  ></i>
                  <h3>
                    <span>DevopS</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-file-list-3-line"
                    style={{ color: "#088a4d" }}
                  ></i>
                  <h3>
                    <span>ReactJS</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-file-list-3-line"
                    style={{ color: "#088a4d" }}
                  ></i>
                  <h3>
                    <span>WordPress</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-file-list-3-line"
                    style={{ color: "#088a4d" }}
                  ></i>
                  <h3>
                    <span>Shopify</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-file-list-3-line"
                    style={{ color: "#088a4d" }}
                  ></i>
                  <h3>
                    <span>Ruby</span>
                  </h3>
                </div>
              </div>
              <div
                className="col-lg-3 col-md-4 col-6 mt-2 mb-2"
                onClick={(e) => navigate(e, "contact")}
              >
                <div className="icon-box">
                  <i
                    className="ri-file-list-3-line"
                    style={{ color: "#088a4d" }}
                  ></i>
                  <h3>
                    <span>NextJs</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Features Section --> */}
        {/* <!-- ======= Cta Section ======= --> */}

        <div className="container">
          <div className="text-center">
            <img
              src={cta_image}
              className="img-fluid"
              alt="StaySafe IT Solutions development cycle, website design, web development, wordpress design, web serivces, idea implementaion, website design company India, StaySafe IT Solutions"
            />
          </div>
        </div>

        {/* <!-- End Cta Section --> */}

        {/* <!-- ======= Portfolio Section ======= --> */}
        <section id="portfolio" className="portfolio">
          <div className="container">
            <div className="section-title">
              <h2>Portfolio</h2>
              <h3>
                Our <span>Portfolio</span>
              </h3>
              <p>
                Check out some of our best success stories where we transformed
                our clients' ideas into a scalable and user-friendly product by
                beating all the challenges with our exceptional efforts.RG
                Infosys India's affordable web services provider company
              </p>
            </div>
            <div className="text-center">
              <img
                src={logo1}
                className="img-fluid"
                alt="StaySafe IT Solutions development cycle, website design, web development, wordpress design, web serivces, idea implementaion, website design company India, StaySafe IT Solutions"
              />
              <img
                src={logo2}
                className="img-fluid"
                alt="StaySafe IT Solutions development cycle, website design, web development, wordpress design, web serivces, idea implementaion, website design company India, StaySafe IT Solutions"
              />
            </div>
          </div>
        </section>
        {/* <!-- End Portfolio Section --> */}

        {/* <!-- ======= Pricing Section ======= --> */}

        <section id="pricing" className="pricing">
          <div className="container">
            <div className="section-title">
              <h2>Pricing</h2>
              <h3>
                Our Competing <span>Prices</span>
              </h3>
              <p>
                We strive to offer the best value for your money by keeping our
                prices competitive without compromising on the quality of our
                products/services.
              </p>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="box">
                  <h3>Basic</h3>
                  <h3>
                    <sup>
                      Attractive single page website with mobile responsive
                    </sup>
                  </h3>
                  <h4>
                    <sup>600 USD</sup>
                  </h4>
                  <ul>
                    <li>
                      Mobile Responsive : Yes
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </li>
                    <li>Source file : Yes</li>
                    <li>Number of pages or screens : 1</li>
                    <li className="">Revisions : 2 times</li>
                    <li className="">Delivery Time : 7 - 10 days</li>
                    <li className="">OR</li>
                    <li className="">Delivery Time : 4 days = 750 USD </li>
                  </ul>
                  <div className="btn-wrap">
                    <a
                      href="#contact"
                      onClick={(e) => navigate(e, "contact")}
                      className="btn-buy"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="box">
                  <h3>Standard (Recommended)</h3>
                  <h3>
                    <sup>
                      Attractive multi page website with mobile responsive
                      custom web page/shopify pages/wordpress pages{" "}
                    </sup>
                  </h3>
                  <h4>
                    <sup>800 USD</sup>
                  </h4>
                  <ul>
                    <li>
                      Mobile Responsive : Yes
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </li>
                    <li>Number of pages or screens : 10</li>
                    <li className="">Revisions : 3 times</li>
                    <li className="">Delivery Time : 10 - 15 days</li>
                    <li className="">OR</li>
                    <li className="">Delivery Time : 7 days = 950 USD </li>
                  </ul>
                  <div className="btn-wrap">
                    <a
                      href="#contact"
                      onClick={(e) => navigate(e, "contact")}
                      className="btn-buy"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="box">
                  <h3>Advance</h3>
                  <h3>
                    <sup>
                      Attractive multi page website with mobile responsive with
                      React js bootstrap advance technology work{" "}
                    </sup>
                  </h3>
                  <h4>
                    <sup>1000 USD</sup>
                  </h4>
                  <ul>
                    <li>
                      Mobile Responsive : Yes
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </li>
                    <li>Source file : Yes</li>
                    <li>Number of pages or screens : 10</li>
                    <li className="">Revisions : 5 times</li>
                    <li className="">Delivery Time : 15 days</li>
                    <li className="">OR</li>
                    <li className="">Delivery Time : 10 days = 1200 USD</li>
                  </ul>
                  <div className="btn-wrap">
                    <a
                      href="#contact"
                      onClick={(e) => navigate(e, "contact")}
                      className="btn-buy"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
              {/* 
                            <div className="col-lg-4 col-md-6">
                                <div className="box">
                                    <h3>SEO</h3>
                                    <h3><sup>Review 3 pages Optimize them fully Fix SEO errors Future advice in plain English</sup></h3>
                                     <h4><sup>₹20,500INR </sup></h4> 
                                    <ul>
                                        <li>Title optimization : Yes<i className="fa fa-check" aria-hidden="true"></i></li>
                                        <li>H1, H2, H3 tags   : Yes</li>
                                        <li>Meta description  : Yes</li>
                                        <li className="">Image alt tags : Yes</li>
                                        <li className="">Schema markup : Yes</li>
                                        <li className="">Page audit   : Yes</li>
                                        <li className="">500 Backlinks   : Yes</li>
                                        <li className="">500 Relevant Niche Backlinks : Yes</li>
                                        <li className="">Number of pages : 5</li>
                                        <li className="">Delivery Time  : 15 days</li>
                                        <li className="">OR</li>
                                        <li className="">Delivery Time  : 8 days	will take 5000/- INR + 25,500 INR = 25,500/-INR </li>
                                    </ul>
                                    <div className="btn-wrap">
                                        <a href="#contact" onClick={(e) => navigate(e, 'contact')} className="btn-buy">Buy Now</a>
                                    </div>
                                </div>
                            </div> */}

              <div className="col-lg-4 col-md-6">
                <div className="box">
                  <h3>Development</h3>
                  <h3>
                    <sup>
                      Backend in JAVA, Backend in GO, Backend in PHYTON,Backend
                      in Node js, Create API, with SQL/NOSQL, Third party API
                      soap API
                    </sup>
                  </h3>
                  {/* <h4><sup>₹40,500INR</sup></h4> */}
                  <ul>
                    <li>
                      Create API In JAVA : Yes
                      <i className="fa fa-check" aria-hidden="true"></i>
                    </li>
                    <li>Create API In GO : Yes</li>
                    <li>Create API In PHYTON : Yes</li>
                    <li className="">integrate third party API : Yes</li>
                    <li className="">Databases : MongoDB,Mysql</li>
                    <li className="">Node js backend : Yes</li>
                    <li className="">Microservices : Yes</li>
                    <li className="">Git repo : Yes</li>
                    <li className="">Soap API : Yes</li>
                    <li className="">Delivery Time : NA</li>
                  </ul>
                  <div className="btn-wrap">
                    <a
                      href="#contact"
                      onClick={(e) => navigate(e, "contact")}
                      className="btn-buy"
                    >
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Pricing Section --> */}
        {/* <!-- ======= Contact Section ======= --> */}
        <section id="contact" className="contact">
          <div className="container">
            <div className="section-title">
              <h2>Contact</h2>
              <h3>
                Contact <span>Us</span>
              </h3>
              <p>
                Transform Your Business With The Innovative and Professional IT
                Solution Provider. Happy customers and ongoing relationships are
                what we strive for. Success is measured by results, the most
                important being how our clients feel about their experience with
                us. We can only comprehend each other if we communicate well.
                Allow us to get a better understanding of your needs or queries.
                Present us the chance to assist you.
              </p>
            </div>

            {/*<div>*/}
            {/*  <iframe*/}
            {/*    style={{ border: 0, width: "100%", height: "270px" }}*/}
            {/*    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621"*/}
            {/*    frameBorder="0"*/}
            {/*    allowFullScreen*/}
            {/*  ></iframe>*/}
            {/*</div>*/}

            <div className="row mt-5">
              <div className="col-lg-4">
                <div className="info">
                  <div className="address">
                    <i className="bi bi-geo-alt"></i>
                    <h4>Location:</h4>
                    <p>
                      <div>India:692 ,IFC PAPER MARKET , GHAZIPUR , DELHI</div>
                    </p>
                  </div>

                  <div className="email">
                    <i className="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>
                      <a
                        href="/cdn-cgi/l/email-protection"
                        className="__cf_email__"
                        data-cfemail="aec1dbdad9cfd7dd80c7c0eec9c3cfc7c280cdc1c3"
                      >
                        sales@staysafeit.com
                      </a>
                    </p>
                  </div>

                  <div className="phone">
                    <i className="bi bi-phone"></i>
                    <h4>Call:</h4>
                    <p>India : +91 8800869033</p>
                  </div>
                  
                </div>
              </div>

              <div className="col-lg-8 mt-5 mt-lg-0">
                <form onSubmit={handleSubmit} className="php-email-form">
                  <div className="row">
                    <div className="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        value={payload?.name}
                        onChange={handleChange}
                        className="form-control clnt-name"
                        id="name"
                        placeholder="Your Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <input
                        type="email"
                        value={payload?.email}
                        className="form-control clnt-email"
                        name="email"
                        id="email"
                        onChange={handleChange}
                        placeholder="Your Email"
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="form-group mt-3">
                                        <input type="text" className="form-control clnt-service" name="subject" onChange={handleChange} id="subject" placeholder="Service name" required />
                                    </div> */}
                  <div className="form-group mt-3">
                    <input
                      type="number"
                      className="form-control clnt-service"
                      value={payload?.mobile}
                      name="mobile"
                      onChange={handleChange}
                      id="Mobile"
                      placeholder="Mobile Number"
                      required
                    />
                  </div>
                  <div className="form-group mt-3">
                    <textarea
                      className="form-control clnt-requirment"
                      name="message"
                      value={payload?.message}
                      onChange={handleChange}
                      rows="5"
                      placeholder="Requirement (in 3-5 lines)"
                      required
                    ></textarea>
                  </div>
                  <div className="my-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="submit-details">
                      Submit Details
                    </button>
                  </div>
                </form>
                <div className="ajax-response"></div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- End Contact Section --> */}
        <footer id="footer">
          <div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 footer-contact">
                  <h2>StaySafe IT Solutions</h2>
                  <p>
                    
                  <p>
                 
                      <div>India: 962 IFC PAPER MARKET , GHAZIPUR , DELHI </div>
                    </p>

                    <br />
                    <br />
                    <strong>Mobile :</strong> 
                    <div>
                    <p>India : +91 8800869033</p>
                    
                    
                    </div>
                    <br />
                    <strong>Email :</strong>{" "}
                    <span
                      className="__cf_email__"
                      data-cfemail="51383f373e113e2425263028227f383f"
                    >
                      info@staysafeit.com
                    </span>
                    <br />
                                      <div className="social-links text-md-right pt-3">
                     <a href="https://twitter.com/InOutways?t=pZ_jsGRY7HDUrXMacZjkmQ&s=08" className="twitter"><i className="bx bxl-twitter"></i></a>
                     <a href="https://www.facebook.com/people/StaySafe-Solutions/61565147460117/" className="facebook"><i className="bx bxl-facebook"></i></a>
                     <a href="https://www.instagram.com/staysafeitsolutions/" className="instagram"><i className="bx bxl-instagram"></i></a> 
                     <a href="https://www.linkedin.com/company/staysafeit" target="_blank" className="linkedin"><i className="bx bxl-linkedin"></i></a>

                                      </div>

                  </p>
                </div>

                <div className="col-lg-2 col-md-6 footer-links">
                  <h4>Useful Links</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">Services</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#about">About Us</a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-3 col-md-6 footer-links">
                  <h4>Our Services</h4>
                  <ul>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">Application Development</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">Mobile app Development</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">QA and Testing</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">Technical Support</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">Big Data</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">Digital Transformation</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">Cloud Computing</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">Robotic Process Automation</a>
                    </li>
                    <li>
                      <i className="bx bx-chevron-right"></i>{" "}
                      <a href="#services">Reporting & Monitoring</a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-6 footer-newsletter">
                  <h4>Let's keep in touch</h4>
                  <p>
                    Subscribe to keep up with fresh news and exciting updates.
                  </p>
                  <form action="" method="post">
                    <input type="email" name="email" />
                    <input type="submit" value="Subscribe" />
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="container d-md-flex py-4">
            <div className="me-md-auto text-center text-md-start">
              <div className="copyright">
                &copy; Copyright{" "}
                <strong>
                  <span>StaySafe IT Solutions</span>
                </strong>
                . All Rights Reserved
              </div>
            </div>
          </div>
        </footer>
        {/* <!-- End Footer --> */}
      </main>
      <a
        href="#"
        className={`back-to-top d-flex align-items-center justify-content-center ${
          scroll ? `active` : ""
        }`}
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </div>
  );
};
export default OnePageComponent;
