import React, { useState, useEffect } from 'react';
import './Popup.css';

const Popup = () => {
    const [showPopup, setShowPopup] = useState(true);
    const [minimizePopup, setMinimizePopup] = useState(false);

    useEffect(() => {
        // Set up a timer to close the popup after 8 seconds
        const timer = setTimeout(() => {
            setShowPopup(false);
        }, 5000); // 8000 milliseconds = 8 seconds

        // Clean up the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []);

    const handleMinimize = () => {
        setMinimizePopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <>
            {showPopup && (
                <div className={`popup ${minimizePopup ? 'minimized' : 'large'}`}>
                    <div className="popup-header">
                        <h2>Welcome to StaySafe IT Solutions</h2>
                        <button className="close-button" onClick={handleClosePopup}>
                            <span className="close-icon">X</span>
                        </button>
                    </div>
                    <div className="popup-body">
                        <p>
                            <h3>Contact us</h3>
                            <div>
                                At StaySafe IT Solutions, we are committed to providing innovative and reliable IT services tailored to your business needs. Whether you're looking for cutting-edge software development, cybersecurity solutions, or cloud integration, our team of experts is here to assist you. Reach out to us today for customized solutions that drive growth and security for your organization.
                            </div>
                        </p>
                        <a href="https://forms.gle/LxmyKUBQsMyBxMW28" target="_blank" rel="noopener noreferrer">
                            <button>Contact us</button>
                        </a>
                    </div>
                </div>
            )}
        </>
    );
};

export default Popup;
