import './App.css';

import OnePageComponent from './onePageComponent';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from './Popup';
function App() {
  return (<>
    <Popup/>
    <OnePageComponent />
    <ToastContainer />
  </>);
}
export default App;